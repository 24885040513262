.day-time-selector {
  width: 5rem;
  height: 2rem;
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 8px;
}

.day-time-btn {
  max-width: 2.125rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.364;
  color: #757575;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  transition: all 200ms;
  user-select: none;

  &::after {
    background: var(--primary);
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: all 200ms cubic-bezier(0.77, 0, 0.175, 1);
    border-radius: 6px;
  }

  &.btn-left::after {
    left: calc(100% + 4px);
    right: -50%;
  }

  &.btn-left.active::after {
    left: 0;
    right: 0;
  }

  &.btn-right::after {
    right: calc(100% + 4px);
    left: -50%;
  }

  &.btn-right.active::after {
    right: 0;
    left: 0;
  }

  .label {
    color: #757575;
    z-index: 1;
    position: relative;
    transition: color 200ms;
  }

  &.active {
    .label {
      color: #ffffff;
    }
  }

  &:not(.active):hover .label {
    color: #111111;
  }
}

.rkt-form-timepicker-field {
  display: flex;
  align-items: flex-end;
}

.hour-selector {
  width: 6rem;
  margin-right: 1.25rem;
  position: relative;

  &::after {
    position: absolute;
    content: ':';
    left: 100%;
    width: 1.25rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9375rem;
    line-height: 1.364;
    color: #111111;
  }
}

.minute-selector {
  width: 6rem;
  margin-right: 0.3125rem;
  position: relative;
}

.time-select.ng-select .ng-placeholder {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.364;
  color: #bababa;
  flex: 1;
}

.time-select.rkt-form-select.ng-dropdown-panel {
  max-width: 5rem !important;
  width: 5rem !important;
  min-width: 5rem !important;

  &.ng-select-top {
    margin-bottom: 0.25rem;
    margin-top: 0;
  }

  .ng-dropdown-panel-items {
    max-height: 21rem;
  }

  .not-found {
    color: var(--danger);
    padding: 0.75rem 1rem;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}

.time-select.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.time-select.ng-select .ng-select-container .ng-value-container .ng-input > input {
  font-size: 0.9375rem;
  line-height: 1.364;
  font-weight: 400;
  color: #111111;
  padding-left: 1.1875rem;
  cursor: pointer;
}
