.modal-content-confirm,
.modal-content-success,
.modal-content-error {
  padding: 2.5rem 1.75rem 2.3125rem;
  position: relative;

  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-close {
    position: absolute;
    top: 1.8125rem;
    right: 1.8125rem;
    font-size: 0.9rem;
    color: rgba(#010d14, 0.4);
  }

  .modal-close:hover {
    color: #010d14;
  }

  .modal-image {
    width: 10.75rem;
    height: 10.75rem;
    margin-bottom: 1.25rem;
    font-size: 7.8925rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
  }

  .modal-title {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333333;
    font-weight: 700;
    margin-bottom: 0.6875rem;
  }

  .modal-sub-title {
    font-size: 1.25rem;
    line-height: 1.34;
    margin-bottom: 0.5625rem;
  }

  .modal-message {
    text-align: center;
    font-size: 1rem;
    line-height: 2;
    margin-bottom: 1.0625rem;
    color: rgba(#333333, 0.6);
  }

  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      width: 10.125rem;
    }

    .button + .button {
      margin-left: 0.875rem;
    }
  }
}
