.rkt-table-search {
  --input-text-color: #111111;
  --input-text-focus-color: var(--primary);
  --input-bg-color: #ffffff;
  --input-border-color: #ffffff;
  --input-border-hover-color: #bababa;
  --input-border-focus-color: var(--primary);
  --search-icon-color: #757575;
  --search-icon-focus-color: #111111;
  --search-icon-active-color: #4a4a4a;
  --clear-icon-color: #757575;
  --clear-icon-focus-color: var(--primary);
  --clear-icon-focus-bg-color: #f0f9f8;
}

.rkt-table-search-container {
  position: relative;
}

.rkt-table-search-label {
  display: flex;
}

.rkt-table-search-input {
  flex: 1;
  padding: 0.6875rem 2.9375rem;
  background: var(--input-bg-color);
  height: 2.75rem;
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
  outline: none;
  font-size: 0.9375rem;
  line-height: 1.34;
  color: var(--input-text-color);
}

.rkt-table-search-icon {
  position: absolute;
  font-size: 1.5rem;
  top: 0.625rem;
  left: 0.75rem;
  user-select: none;
  pointer-events: none;
  color: var(--search-icon-color);
}

.rkt-table-search-clear-btn {
  position: absolute;
  font-size: 1.5rem;
  top: 0.375rem;
  right: 0.375rem;
  color: var(--clear-icon-color);
  cursor: pointer;
  width: 2.25rem;
  border-radius: 8px;
  bottom: 0.375rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.rkt-table-search-clear-btn:hover {
  background: var(--clear-icon-focus-bg-color);
  color: var(--clear-icon-focus-color);
}

.rkt-table-search-container.disabled {
  opacity: 0.3;
}

.rkt-table-search-container:hover {
  .rkt-table-search-icon {
    color: var(--search-icon-focus-color);
  }

  .rkt-table-search-input {
    border-color: var(--input-border-hover-color);
  }
}

.rkt-table-search-container:focus-within {
  .rkt-table-search-icon {
    color: var(--search-icon-focus-color);
  }

  .rkt-table-search-input {
    border-color: var(--input-border-focus-color);
  }
}

.rkt-table-search-container.has-value {
  .rkt-table-search-icon {
    color: var(--search-icon-active-color);
  }
}
