$zIndexUserMenu: 1021;

.rkt-menu.user-dropdown {
  z-index: $zIndexUserMenu;
  box-shadow: none;
  border: 1px solid #e2e3e4;
  border-radius: 8px;
  position: fixed;
  display: block !important;
  top: auto !important;
  margin-left: 5.25rem;
  bottom: 0.8125rem !important;
  padding: 0;
  overflow: hidden;

  &.expanded {
    margin-left: 15.75rem;
  }

  .rkt-menu-item-link {
    color: #4a4e52;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375;
    height: 2.875rem;
    padding: 0.75rem 1rem;

    .rkt-menu-item-text {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375;
    }
  }

  .rkt-menu-item-link:hover {
    background: #f4f5f8;
    color: #111111;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375;
  }

  .rkt-menu-item-icon {
    width: auto;
    margin-right: 0;
    font-size: 1.25rem;

    .rkt-icon {
      margin-right: 0.5rem;
    }
  }
}
