:root {
  --page-header-title-color: #111111;
  --error-color: #ff6e6e;
  --attention-modal-title-color: #111111;
  --attention-modal-message-color: #757575;
  --attention-modal-bg-color: #ffffff;
  --form-field-placeholder-color: #bababa;
  --table-disabled-row-text-color: #bababa;
  --close-button-color: #757575;
  --close-button-hover-bg-color: #e2e3e4;
}

// old
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1051;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-overlay {
  display: none;
  overflow-y: scroll;

  &.is-open {
    display: block;
  }

  &.is-draggable {
    overflow-y: auto;
  }

  .modal-content {
    border: none;
    margin: 0 auto;
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
    width: var(--modal-content-width);
  }
}

.modal-backdrop {
  background: rgb(188 188 188 / 40%);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin: 0 auto;
  padding: 1.75rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-md {
  width: 37.75rem;
}

.modal-dialog.modal-full-size {
  max-width: calc(100% - 4rem);
  min-height: calc(100vh - 6.875rem);
  margin-top: 6.875rem;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;

  .modal-content {
    min-height: calc(100vh - 6.875rem);
    background: #ffffff;
    border: #e7e8e9;
  }
}

// new
.modal-content-attention {
  padding: 2.5rem 1.75rem;

  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    text-align: center;
    color: var(--attention-modal-title-color);
    margin-bottom: 0.75rem;
  }

  .modal-message {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
    text-align: center;
    color: var(--attention-modal-message-color);
  }

  .modal-buttons {
    margin-top: 1.75rem;
    display: flex;
    gap: 1.5rem;
  }

  .modal-icon {
    background-repeat: no-repeat;
    width: 8.25rem;
    height: 8.25rem;
    margin-bottom: 0.75rem;
  }

  .modal-icon-error {
    background-image: url('/assets/images/speech-bubble-alert.svg');
  }

  .modal-icon-success {
    background-image: url('/assets/images/speech-bubble-success.svg');
  }

  .modal-icon-warning {
    background-image: url('/assets/images/speech-bubble-warning.svg');
  }
}

.modal-overlay.auth-error-modal,
.modal-overlay.success-modal,
.modal-overlay.confirm-modal {
  --modal-content-width: 23.625rem;

  .modal-content {
    margin: 0 auto;
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
  }
}

.modal-overlay.change-password-modal {
  --modal-content-width: 30.375rem;
}

.modal-overlay.confirm-modal.note-delete-confirm {
  .modal-content {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 50%);
  }
}

.modal-overlay.select-filter-list-modal {
  --modal-content-width: 66.75rem;

  .modal-lg {
    max-width: initial;
  }

  .rkt-table .header-cell-inner {
    background: white;
  }

  .rkt-table .header-cell {
    font-weight: 600;
    color: #4a4a4a;
  }

  .rkt-table .datatable-row-wrapper:not(:first-child) .body-cell {
    border: none;
  }

  .rkt-table-interactive .datatable-body-row .body-cell-inner {
    background: #f9f9f9;
  }

  .rkt-table-interactive .datatable-body-row:hover .body-cell-inner,
  .rkt-table-interactive .datatable-body-row.active:hover .body-cell-inner {
    background: #ededed;
  }

  .rkt-table-interactive .datatable-body-row.active .body-cell-inner {
    background: #f9f9f9;
  }

  .rkt-table-interactive .datatable-body-row .body-cell-name .body-cell-inner {
    &::before {
      content: '';
      background-image: url('/assets/images/checkbox.svg');
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      background-size: 24px;
      background-repeat: no-repeat;
      flex-shrink: 0;
    }
  }

  .rkt-table-interactive .datatable-body-row.active .body-cell-name .body-cell-inner {
    &::before {
      background-image: url('/assets/images/checkbox-active.svg');
    }
  }
}

.modal-overlay.entity-form-modal {
  --close-button-color: #757575;
  --close-button-hover-bg-color: #e2e3e4;
  --modal-title-color: #111111;
  --buttons-container-border-color: #e2e3e4;

  .content {
    overflow-y: scroll;
    position: relative;
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  .close-button {
    position: absolute;
    right: 2.25rem;
    top: 1.875rem;
    font-size: 1.5rem;
    color: var(--close-button-color);

    &:hover {
      background: var(--close-button-hover-bg-color);
      border-radius: 50%;
      outline: 2px solid var(--close-button-hover-bg-color);
    }
  }

  .title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--modal-title-color);
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  .row-with-separator {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--buttons-container-border-color);
  }

  .modal-content {
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
    margin: 0 auto;
  }

  .modal-body {
    padding: 1.75rem 2.25rem;
    position: relative;
  }
}

.modal-content-confirm.brand-fee-confirm {
  .modal-image {
    background-image: url('/assets/images/ok-hand.svg');
    width: 10.5625rem;
    height: 8.6875rem;
    margin-bottom: 0.75rem;
  }

  .modal-message {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 28px;
  }

  .modal-buttons {
    gap: 1.5rem;
  }
}

.modal-overlay.batch-errors-modal {
  --modal-content-width: 50rem;
}

.modal-overlay.filter-edit-modal {
  .modal-dialog,
  .modal-content,
  .modal-body {
    max-height: calc(100vh - 120px);
  }

  .modal-body {
    overflow: hidden;
  }
}

.modal-overlay.select-template-modal {
  --modal-content-width: 66.75rem;

  .modal-lg {
    max-width: initial;
  }

  .rkt-table .header-cell-inner {
    background: white;
  }

  .rkt-table .header-cell {
    font-weight: 600;
    color: #4a4a4a;
  }

  .rkt-table .datatable-row-wrapper:not(:first-child) .body-cell {
    border: none;
  }

  .rkt-table-interactive .datatable-body-row {
    .body-cell-inner {
      background: #f9f9f9;
      border: 1px solid transparent;
    }

    .body-cell-name .body-cell-inner {
      border-right: none;
    }

    .body-cell-editTemplateBtn .body-cell-inner {
      border-left: none;
    }

    .body-cell-message .body-cell-inner {
      border-left: none;
      border-right: none;
    }

    .icon-button-cell {
      display: none;

      .rkt-icon {
        color: #4a4a4a;
      }
    }
  }

  .rkt-table-interactive .datatable-body-row:hover .body-cell-inner,
  .rkt-table-interactive .datatable-body-row.active:hover .body-cell-inner {
    background: #ebedf2;

    .icon-button-cell {
      display: flex;
    }
  }

  .rkt-table-interactive .datatable-body-row.active .body-cell-inner {
    background: #f9f9f9;
    border-color: var(--primary);
  }

  .rkt-table-interactive .datatable-body-row:hover .body-cell-inner,
  .rkt-table-interactive .datatable-body-row.active .body-cell-inner {
    .icon-button-cell:hover {
      background-color: white;

      .rkt-icon {
        color: var(--primary);
      }
    }
  }
}

.placeholder-form-modal {
  .rkt-form-input-field {
    &::before,
    &::after {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 400;
      color: var(--text-primary);
    }

    &::before {
      content: '{{custom.';
      width: 4.4375rem;
    }

    &::after {
      content: '}}';
      width: 1.1875rem;
      justify-content: flex-end;
    }
  }
}

.add-placeholder-button {
  color: var(--primary);
  font-size: 0.875rem;
  line-height: 1.428;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;

  .rkt-icon {
    flex-shrink: 0;
    font-size: 1.25rem;
  }

  &:hover {
    color: var(--btn-primary-hover-bg);
  }
}

rkt-modal + rkt-modal {
  .modal-backdrop {
    z-index: 1053;
  }

  .modal-overlay {
    z-index: 1054;
  }

  .modal {
    z-index: 1055;
  }
}
