@use 'sass:math';

$formFieldWidth: 637;
$formWidth: 1450;

.rocket-ui-auth-form-control:not(:first-child) .form-group {
  margin-top: 2.1875rem;
}

.rocket-ui-auth-form-control-inner-group .form-group {
  margin-top: 2.1875rem;
}

.rocket-ui-auth-form-control .form-control-input {
  border: 1px solid #e2e3e4;
  border-radius: 4px;
  width: 100%;
  height: 3rem;
  font-size: 1.125rem;
  line-height: 1.34;
  color: #4a4e52;
  padding: 0.75rem 1.0625rem;
}

.rocket-ui-auth-form-control .password-visibility-button {
  display: flex;
  align-items: center;
  padding-left: 20px !important;
  color: #757575 !important;
}

.rocket-ui-auth-form-control .password-visibility-button:hover {
  color: #111111 !important;
}

.rocket-ui-auth-form-control .form-control-label {
  font-size: 0.875rem;
  line-height: 1.357;
  color: rgba(#050505, 0.8);
  margin-bottom: 0.4375rem;
  display: inline-flex;
}

.rocket-ui-auth-form-control .required-asterisk::after {
  content: '*';
}

.rocket-ui-form-control {
  display: flex;
  flex: 1 1 auto;

  rkt-form-control-wrapper {
    flex: 1 1 100%;
  }

  .form-control-label {
    font-size: 13px;
    line-height: 1.384;
    color: #111111;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
  }

  .required-asterisk::after {
    content: '*';
  }

  .form-control-input {
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    font-size: 15px;
    line-height: 1.34;
    color: #111111;
    padding: 12px 20px;
    text-overflow: ellipsis;
  }

  .iti__selected-flag {
    padding-left: 20px;
    padding-right: 10px;
  }

  .iti .form-control {
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    font-size: 15px;
    line-height: 1.34;
    color: #111111;
    padding: 12px 20px 12px 66px;
    text-overflow: ellipsis;
  }

  .form-control-input:disabled {
    opacity: 0.5;
  }

  .form-control-textarea {
    padding: 12px 20px;
    background: #f9f9f9;
    border-radius: 4px;
    height: 122px !important;
    width: 100%;
    border: 1px solid #f9f9f9;
    resize: none;
    color: #111111;
  }

  .form-control-textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(var(--primary) 0.5);
  }

  .form-control-input.is-invalid {
    background: #ffffff;
    border-color: #d90125;
    padding-right: 0.75rem;
  }

  .form-control-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgb(217 1 37 / 25%);
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #d90125;
}

.form-control.is-invalid {
  background: #ffffff;
  border-color: #d90125;
  padding-right: 0.75rem;
}

.form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgb(217 1 37 / 25%);
}

.registry-form .rkt-form .rkt-form-textarea {
  height: 7.625rem;
}

.save-error-message {
  color: #d90125;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  white-space: pre-wrap;
}

@media (width <= 700px) {
  .brand-registry-form {
    .rocket-ui-form-control {
      width: 100%;
      max-width: 100%;
    }

    .rocket-ui-form-control:nth-child(2n) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}

.form-control-tooltip {
  position: relative;
  margin-left: 0.375rem;
}

.form-control-tooltip-message {
  position: absolute;
  bottom: 100%;
  width: 15.625rem;
  left: 100%;
  margin-bottom: 0.625rem;
  margin-left: -7.8125rem;
  background: #e6e6e6;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 0.75rem;
}

.form-control-tooltip-message::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.875rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: #e6e6e6 transparent transparent;
}

.rkt-form-input:focus {
  border-color: var(--primary) !important;
}

.details-page-edit-form .rkt-form .rkt-form-textarea-field-with-counter {
  height: 16.25rem;

  .rkt-form-textarea {
    height: calc(100% - 1.25rem);
  }

  .rkt-form-textarea-counter::after {
    content: 'characters used';
  }
}
