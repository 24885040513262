@use 'sass:color';

$dim-gray: #707070;
$dark-gray: #333333;
$light-gray: #f7f7f7;
$light-blue: #f5faff;
$gray: #e2e3e4;
$placeholder-bg: #ced4da99;
$row-hover-bg: #e7e8e9;
$sort-btn-color: #939b9a;
$pager-pages-color: #2e2b57;
$header-cell-color: #001411;
$focus-shadow: 0 0 0 0.2rem rgba(color.mix(#0453f4, #0453f4, 15%), 0.5);

.rkt-table.rkt-table-sticky-header {
  overflow: unset !important;

  .datatable-header {
    overflow: unset !important;
    position: sticky;
    top: 0 !important;
    z-index: 100;

    &:before {
      content: ' ';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.ngx-datatable.no-sticky-header {
  overflow: unset;

  .datatable-header {
    overflow: unset;
  }
}

.ngx-datatable .datatable-body .datatable-scroll {
  display: block !important;
  width: 100% !important;
}

.empty-campaign-list {
  flex-direction: column;
  height: 280px;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;

  .empty-list-icon {
    width: 151px;
    height: 119px;
    background-image: url('/assets/images/empty-campaign-list.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    margin-bottom: 20px;
  }

  .empty-list-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 4px;
  }

  .empty-list-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    color: #757575;
    text-align: center;
  }
}

.empty-campaign-details-list {
  .empty-list-icon {
    width: 151px;
    height: 104px;
    background-image: url('/assets/images/empty-campaign-details-list.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    margin-bottom: 16px;
  }
}

.empty-users-list {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;

  .empty-list-icon {
    width: 151px;
    height: 104px;
    background-image: url('/assets/images/empty-campaign-details-list.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    margin-bottom: 16px;
  }

  .empty-list-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    color: #757575;
    text-align: center;
  }
}

.empty-filters-list {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;

  .empty-list-icon {
    width: 151px;
    height: 104px;
    background-image: url('/assets/images/empty-list.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    margin-bottom: 16px;
  }

  .empty-list-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    color: #757575;
    text-align: center;
    margin-bottom: 1.25rem;
  }
}

.empty-recipients-list {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;

  .empty-list-icon {
    width: 151px;
    height: 119px;
    background-image: url('/assets/images/empty-recipients-list.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    margin-bottom: 20px;
    flex-shrink: 0;
  }

  .empty-list-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    color: #111111;
    text-align: center;
    margin-bottom: 20px;
  }
}

.user-list {
  .header-cell.header-cell--createdAtFormatted .header-cell-inner {
    padding-right: 0;
  }

  .body-cell.body-cell--createdAtFormatted .body-cell-inner {
    justify-content: flex-end;
  }
}

.body-cell-messageText .body-cell-inner,
.body-cell-responseText .body-cell-inner {
  position: relative;

  .body-cell-value:not(.no-value) {
    margin-left: 1.75rem;
  }
}

.body-cell-messageText .body-cell-inner .body-cell-value:not(.no-value)::before {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgPGNpcmNsZSBjeD0iMTAiIGN5PSI5LjUiIHI9IjciIGZpbGw9IiNmZmYiLz4KICA8cGF0aCBmaWxsPSIjQkFCQUJBIiBkPSJNMTguMzMzIDEwLjAwMWE4LjMzMyA4LjMzMyAwIDEgMC0xNi42NjcgMCA4LjMzMyA4LjMzMyAwIDEgMCAxNi42NjcgMFptLTkuMTY3IDQuMTY3VjguNjhsLTEuOTEgMS45MS0xLjE3OS0xLjE3OEwxMCA1LjQ5bDMuOTIyIDMuOTIyLTEuMTc4IDEuMTc4LTEuOTExLTEuOTF2NS40ODhIOS4xNjZaIi8+Cjwvc3ZnPgo=');
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 8px;
}

.body-cell-responseText .body-cell-inner .body-cell-value:not(.no-value)::before {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgPGNpcmNsZSBjeD0iMTAiIGN5PSI5LjUiIHI9IjciIGZpbGw9IiNmZmYiLz4KICA8cGF0aCBmaWxsPSIjMTc1NzcwIiBkPSJNMTguMzMzIDEwLjAwMWMwLTQuNTk1LTMuNzM4LTguMzMzLTguMzMzLTguMzMzLTQuNTk1IDAtOC4zMzQgMy43MzgtOC4zMzQgOC4zMzMgMCA0LjU5NSAzLjczOSA4LjMzNCA4LjMzNCA4LjMzNHM4LjMzMy0zLjczOSA4LjMzMy04LjMzNFptLTExLjYzIDEuMjE1IDEuMTc4LTEuMTc5IDEuMjg1IDEuMjg2VjUuODM1aDEuNjY3djUuNDg4bDEuMjg2LTEuMjg2IDEuMTc4IDEuMTc4TDEwIDE0LjUxNGwtMy4yOTgtMy4yOTdaIi8+Cjwvc3ZnPgo=');
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 8px;
}

.rkt-table .checkbox-cell .checkbox-btn {
  padding-top: 5px;
  padding-bottom: 5px;
}

.empty-list .rkt-table .checkbox-cell .checkbox-btn {
  cursor: default;
  pointer-events: none;

  .checkbox-icon {
    color: #bababa !important;
  }
}

.empty-templates-list {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;

  .empty-list-icon {
    width: 151px;
    height: 119px;
    background-image: url('/assets/images/empty-templates-list.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    margin-bottom: 20px;
    flex-shrink: 0;
  }

  .empty-list-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    color: #111111;
    text-align: center;
    margin-bottom: 20px;
  }
}
