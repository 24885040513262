:root {
  --table-body-empty-value-color: #bababa;
}

.rkt-table {
  overflow: unset !important;

  .datatable-header {
    background: var(--white);
    border-radius: 8px 8px 0 0;
    overflow: unset !important;
  }

  .datatable-header-cell .sort-btn {
    display: none !important;
  }

  .datatable-body {
    background: var(--white);
  }

  .datatable-body .datatable-scroll {
    display: block !important;
    width: 100% !important;
  }

  .datatable-header .datatable-row-center {
    border-radius: 6px;
  }

  &:not(.checkbox-selection) {
    .datatable-body-row.active .body-cell-inner {
      background: #f0f9f8;
    }
  }

  &.checkbox-selection .datatable-body-row-disabled {
    cursor: not-allowed;

    &:hover .body-cell-inner {
      background: transparent;
      cursor: not-allowed;
    }

    .body-cell {
      color: #bababa;
      cursor: not-allowed;
    }

    .body-cell-value.status-tag {
      opacity: 0.4;
    }

    .checkbox-btn {
      opacity: 0.2;
      cursor: default;

      &::after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHJlY3Qgd2lkdGg9IjE1IiBoZWlnaHQ9IjE1IiB4PSI0LjUiIHk9IjQuNSIgZmlsbD0iY3VycmVudENvbG9yIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgcng9IjMuNSIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Im0xNi44NzcgOS44ODgtNi4wMDYgNS41YS40MjEuNDIxIDAgMCAxLS4yOTYuMTEyLjQ0Ny40NDcgMCAwIDEtLjI5NS0uMTE0bC0zLjE1OS0yLjk0YS4zODUuMzg1IDAgMCAxLS4wOS0uMTI1LjM2LjM2IDAgMCAxIC4wOTMtLjQybC43OS0uNzI0YS40MjMuNDIzIDAgMCAxIC4yOTYtLjExMS40NDcuNDQ3IDAgMCAxIC4yOTUuMTE0bDIuMDggMS45MzYgNC45Mi00LjUwNGEuNDQ4LjQ0OCAwIDAgMSAuNTkuMDAybC43ODQuNzNhLjM3LjM3IDAgMCAxIC4xMjEuMjczYzAgLjEwMi0uMDQ1LjItLjEyMy4yNzJaIi8+Cjwvc3ZnPgo=');
        content: '';
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .checkbox-icon {
      display: none;
    }
  }

  .datatable-row-wrapper:not(:first-child) .body-cell {
    border-top: 2px solid rgba(#f7f7f7, 0.5);
  }

  .datatable-row-center .datatable-header-cell:first-child,
  .datatable-row-center .datatable-body-cell:first-child {
    .body-cell {
      padding-left: 4px;
    }

    .header-cell {
      margin-left: 4px;
    }

    .header-cell-inner,
    .body-cell-inner,
    .placeholder-cell .placeholder-row-content {
      border-radius: 6px 0 0 6px;
    }
  }

  .datatable-row-center .datatable-header-cell:last-child,
  .datatable-row-center .datatable-body-cell:last-child {
    .body-cell {
      padding-right: 4px;
    }

    .header-cell {
      margin-right: 4px;
    }

    .header-cell-inner,
    .body-cell-inner {
      padding-right: 20px;
    }

    .header-cell-inner,
    .body-cell-inner,
    .placeholder-cell .placeholder-row-content {
      border-radius: 0 6px 6px 0;
    }
  }

  .header-cell {
    display: flex;
    font-size: 14px;
    line-height: 1.357;
    color: #4a4a4a;
    padding-top: 4px;
  }

  .header-cell-inner {
    flex: 1;
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 17px;
    background: rgba(var(--primary), 0.1);
  }

  .header-cell-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-cell-sortable {
    cursor: pointer;

    &:hover {
      color: #111111;

      .sort-icon.inactive {
        color: var(--primary);
      }
    }

    .column-sort-btn {
      margin-left: 0.25rem;
      font-size: 1rem;
    }

    .sort-icon.inactive {
      color: #4a4a4a;
    }

    .sort-icon:not(.inactive) {
      color: var(--primary);
    }
  }

  .checkbox-cell {
    user-select: none;

    .checkbox-btn {
      cursor: pointer;
    }

    .checkbox-btn:not(:disabled):hover .checkbox-icon {
      color: var(--primary);
    }

    .header-cell-inner,
    .body-cell-inner {
      padding-left: 0;
      padding-right: 0;
    }

    .checkbox-icon {
      font-size: 24px;
      color: #bababa;
    }

    .checkbox-indeterminate {
      color: #111111;
    }

    .checkbox-on {
      color: var(--primary);
    }
  }

  .expand-cell {
    user-select: none;

    .expand-btn {
      cursor: pointer;
    }

    .expand-btn:not(:disabled):hover .rkt-icon {
      background: #e2e3e4;
      border-radius: 50%;
      outline: 2px solid #e2e3e4;
    }

    .expand-btn.expanded .rkt-icon {
      color: var(--primary);
    }

    .header-cell-inner,
    .body-cell-inner {
      padding-left: 0;
      padding-right: 0.25rem;
    }

    .rkt-icon {
      font-size: 1rem;
      color: #757575;
    }
  }

  .resize-handle--not-resizable {
    height: 0;
  }

  .body-cell {
    display: flex;
    font-size: 15px;
    line-height: 1.34;
    color: #111111;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .body-cell-inner {
    flex: 1;
    align-items: flex-start;
    display: flex;
    overflow: hidden;
    padding: 0.5rem 1.0625rem 0.5rem 1.25rem;
  }

  .body-cell-inner-status-tag {
    padding: 0.25rem 1.0625rem 0.25rem 1.25rem;
  }

  .body-cell-inner-icon-button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .body-cell-inner-icon {
    align-items: center;

    .rkt-icon {
      font-size: 1.5rem;
      color: #757575;
    }
  }

  .body-cell-inner-button {
    flex: 1;
    align-items: center;
  }

  .body-cell-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-button-cell {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .rkt-icon {
      font-size: 1.5rem;
      color: var(--primary);
    }

    &:not(:disabled):hover {
      background: #e2e3e4;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .two-button-cell {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .rkt-icon {
      font-size: 1.5rem;
      color: var(--primary);
    }

    &:not(:disabled):hover {
      background: #e2e3e4;
    }
  }

  .button-cell {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
    border-radius: 8px;
    color: var(--primary);
    height: 2.25rem;

    .rkt-icon {
      font-size: 1.25rem;
      color: var(--primary);
      flex-shrink: 0;
    }

    &:not(:disabled):hover {
      background: #f0f6f6;
      color: #111111;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .body-cell-value.status-tag {
    padding: 6px 12px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    color: #111111;
    background: rgba(#ffb257, 0.2);
  }

  .body-cell-value.status-tag.no-value {
    background: transparent;
  }

  .body-cell-value.status-tag-active,
  .body-cell-value.status-tag-approved,
  .body-cell-value.status-tag-settled,
  .body-cell-value.status-tag-cleared,
  .body-cell-value.status-tag-completed {
    color: #111111;
    background: #d9f4d3;
  }

  .body-cell-value.status-tag-new,
  .body-cell-value.status-tag-pending .body-cell-value.status-tag-processing {
    color: #111111;
    background: rgba(#ffb257, 0.5);
  }

  .body-cell-value.status-tag-suspended,
  .body-cell-value.status-tag-error,
  .body-cell-value.status-tag-declined,
  .body-cell-value.status-tag-canceled,
  .body-cell-value.status-tag-cancelled,
  .body-cell-value.status-tag-deleted {
    color: #ffffff;
    background: #ff6e6e;
  }

  .header-cell-align-right .header-cell-inner,
  .body-cell-align-right .body-cell-inner {
    justify-content: flex-end;
  }

  .header-cell-align-center .header-cell-inner,
  .body-cell-align-center .body-cell-inner {
    justify-content: center;
  }

  .body-cell-multiline .body-cell-value {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .placeholder-cell-inner {
    display: flex;
    flex: 1;
    margin-left: 20px;
    margin-right: 17px;
    height: 20px;
    background: #f5f5f5;
    border-radius: 6px;
    align-self: center;
    position: relative;
    overflow: hidden;
  }

  .placeholder-cell-inner::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #f5f5f5 0%, #e9e9e9 50%, #f5f5f5 100%) 0 0 / 90% 100% no-repeat #f5f5f5;
    transform: translateX(-100%);
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-name: table-placeholder-animation;
  }

  .empty-row {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428;
    text-align: center;
    padding: 40px 0;
    color: #757575;
  }

  .combined-value-id {
    margin-bottom: 0.125rem;
  }

  .combined-value-date-time {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.417;
    color: #757575;
  }
}

.rkt-table-wrapper {
  position: relative;
}

.rkt-table.rkt-table-sticky-header {
  overflow: unset;

  .datatable-header {
    overflow: unset;
    position: sticky;
    top: 60px;
    z-index: 100;
  }
}

.rkt-table-clickable .datatable-body-row:hover {
  cursor: pointer;
}

.rkt-table-interactive .datatable-body-row:hover .body-cell-inner {
  background: #f0f9f8;
}

.rkt-table:not(.rkt-table-initial-loading) .datatable-footer {
  border-top: 2px solid rgba(#f7f7f7, 0.5);
}

.rkt-table.rkt-table-no-footer .datatable-footer {
  border: none;
}

.rkt-table .datatable-footer {
  background: var(--white);
  border-radius: 0 0 8px 8px;

  .rkt-table-pager {
    flex: 1;
    text-align: center;
  }

  .page-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    gap: 0.5rem;
  }

  .page-item {
    min-width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .page-item:not(.page-item-arrow-prev) {
    font-weight: 400;
    font-size: 0.875rem;
  }

  .rkt-icon {
    font-size: 1.25rem;
    margin: 0 auto;
  }

  .page-button {
    padding: 0.5rem 0.25rem;
    height: 100%;
    flex: 1;
    border-radius: 6px;
    color: #111111;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
  }

  .page-button:not(.disabled):hover,
  .page-button.active {
    color: var(--primary);
  }

  .page-button.active {
    border-color: var(--primary);
    cursor: default;
  }

  .page-button.disabled {
    cursor: default;
    color: #bababa;
  }
}

.page-item.ellipsis {
  .page-button {
    .button-text {
      display: block;
    }

    .arrow-icon {
      display: none;
    }
  }

  .page-button:hover {
    .button-text {
      display: none;
    }

    .arrow-icon {
      display: block;
    }
  }
}

@keyframes table-placeholder-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.rkt-table-items-align-center .body-cell-inner {
  align-items: center;
}

.rkt-table .two-line-col-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.no-value {
    font-weight: 400;
    color: var(--table-body-empty-value-color);
  }
}
