:root {
  --container-bg-color: #ffffff;
  --title-color: #111111;
  --address-container-bg-color: #f7f7f7;
  --address-container-border-color: #e2e3e4;
  --note-field-text-color: #111111;
  --profile-title-color: #111111;
  --profile-title-icon-color: #000000;
  --profile-control-button-color: #0453f4;
  --profile-control-button-hover-color: var(--secondary);
  --profile-separator-color: #e2e3e4;
}

.entity-details-container {
  background: var(--container-bg-color);
  border-radius: 8px;
  padding: 1.25rem 1.5rem;
  flex-shrink: 0;
}

.entity-details-title {
  color: var(--title-color);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 0.75rem;
}

.entity-details-address-container {
  background: var(--address-container-bg-color);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin: 0 -1rem 0.75rem;

  &.no-offset-bottom {
    margin-bottom: 0;
  }

  .details-row {
    display: flex;
    gap: 12px 48px;
    flex-wrap: wrap;
  }

  .details-item-container {
    flex: 0 0 auto;
  }
}

.details-row {
  display: grid;
  gap: 16px 66px;
  margin-right: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.details-item-container {
  flex: 1;
  min-width: 0;
}

.details-row-with-offset {
  margin-bottom: 12px;
}

.entity-details-address-title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.428;
  color: var(--title-color);
  margin-bottom: 0.5rem;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid var(--address-container-border-color);
}

.details-page-edit-form-error {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.384;
  color: var(--error-color);
  margin-top: 1.25rem;
}

.push-notification-form {
  .rkt-form-repeat {
    grid-template-columns: 1fr;
  }
}

.details-page-edit-form {
  .rkt-form .rkt-form-row-group {
    margin: 0;
  }

  .rkt-form .rkt-form-textarea {
    height: 6.75rem;
  }

  .rkt-form-control {
    margin-bottom: 0.5rem;
  }

  .note-container {
    margin-top: 0.5rem;
  }

  .note-field-wrapper {
    --note-wrapper-border-color: #e2e3e4;
  }

  .note-field {
    resize: none;
    padding: 0.6875rem 1.1875rem;
    color: var(--note-field-text-color);
    font-size: 0.9375rem;
    line-height: 1.33;
    height: 5.75rem;
    font-weight: 400;
    flex: 1;
    min-width: 0;
  }

  .note-field:focus {
    outline: 0;
    border: none;
  }
}

.integrated-bank-account-form .rkt-form .rkt-form-row-group {
  .rkt-form-row {
    margin-top: 1.25rem;
  }

  .account-type-row {
    margin-top: 0.5rem;
  }
}

.details-section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4375;
    color: var(--profile-title-color);
    display: flex;
    align-items: center;

    .rkt-icon {
      font-size: 1.25rem;
      margin-right: 0.5rem;
      color: var(--profile-title-icon-color);
    }
  }

  .controls {
    display: flex;
  }
}

.details-section {
  padding: 0 1.75rem;
}

.create-update-section {
  .details-row:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.id-row {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.417;
  color: var(--text-secondary);
  margin-bottom: 1.25rem;
}

.business-name-row {
  margin-bottom: 0.75rem;
}

.details-separator {
  border-top: 1px solid var(--profile-separator-color);
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}

.meta-data-notes-section {
  display: flex;
  gap: 4rem;

  .metadata {
    flex: 1;
  }

  .notes {
    flex: 0 0 19.75rem;
  }
}
