@use 'sass:color';

.submit-btn {
  height: 3rem;
  width: 14.3125rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.34;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.5s linear infinite;
}

.invisible-control {
  visibility: hidden;
  position: absolute;
}

.report-btn .btn-success {
  width: 10.625rem;
  height: 3.1875rem;
}
