// Brand Select START
.brand-select.ng-select.ng-select-opened {
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--primary);

  .ng-arrow-wrapper .ng-arrow {
    opacity: 1;
    rotate: 180deg;
  }
}

.brand-select.ng-select {
  height: 2.6875rem;
  border-radius: 8px;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;

  .ng-arrow-wrapper .ng-arrow {
    opacity: 0.3;
    transition: rotate 0.1s;
    width: 0.6669rem;
    height: 0.375rem;
    margin-top: 1.1356rem;
    margin-right: 1.1669rem;
    background-image: url('/assets/images/chevron-down-dark.svg');
    background-repeat: no-repeat;
    background-color: transparent;
  }
}

.brand-select.ng-select:not(.ng-select-opened):hover {
  border-color: #e2e3e4;

  .ng-arrow-wrapper .ng-arrow {
    opacity: 1;
  }
}

.brand-select.ng-select-disabled .ng-value-label {
  color: rgba(#bdbdbd, 0.8);
}

.brand-select.ng-dropdown-panel {
  border: 1px solid #e2e3e4;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0;
  width: 17.125rem !important;
  margin-top: 0.25rem;
  overflow: hidden;
  position: fixed;
  top: 4.0625rem !important;

  .ng-dropdown-footer {
    border-top: 1px solid #e2e3e4;
    padding-top: 8px;
    margin-top: 8px;
  }
}

.brand-select.ng-select .ng-select-container {
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.brand-select .ng-select-container .ng-value-container {
  padding-left: 16px;
  padding-right: 32px;
}

.brand-select .ng-select-container .ng-value-container .ng-value {
  flex: 1;

  .brand-avatar {
    background: var(--secondary);
  }
}

.brand-select.ng-select .ng-select-container .ng-value-container .ng-input {
  padding-right: 3rem;
  padding-left: 2.75rem;
  display: none;
}

.brand-select.ng-select .ng-arrow-wrapper {
  display: flex;
  position: absolute;
  inset: 0;
  justify-content: flex-end;
}

.brand-select.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 14.375rem;
}

.brand-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  display: flex;
  align-items: center;
  color: #4a4e52;
  font-size: 1rem;
  line-height: 1.375;
  position: relative;

  &:not(.ng-option-selected) .brand-option.status-pending,
  &:not(.ng-option-selected) .brand-option.status-expired {
    .brand-avatar {
      background: #bababa;
    }

    .ng-option-label {
      color: #bababa;
    }
  }
}

.brand-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(.ng-option-disabled):hover {
  background-color: #f4f5f8;

  .ng-option-label {
    color: #111111;
  }
}

.brand-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.brand-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:hover {
  background: #ffffff;

  .brand-avatar {
    background: var(--secondary);
  }

  .ng-option-label {
    font-weight: 700;
    color: var(--primary);
  }
}

.brand-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  cursor: default;
}

.brand-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  cursor: default;
}

.brand-select.ng-dropdown-panel .add-brand-button {
  font-weight: 400;
  text-align: left;
  color: #111111;
  width: 17rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding: 0.75rem 1rem;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375;
  display: flex;

  .add-brand-button-icon {
    color: #757575;
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.brand-select.ng-dropdown-panel .add-brand-button:not(.disabled):hover {
  background-color: #f4f5f8;
}

.brand-select.ng-dropdown-panel .add-brand-button-icon {
  font-size: 1.25rem;
  margin-right: 0.625rem;
  color: #bdbdbd;
}

.brand-select .ng-option-label {
  font-weight: 400;
  line-height: 1.375;
  text-align: left;
  color: #4a4e52;
  width: 17rem;
  height: 1.375rem;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.brand-select .brand-option {
  display: flex;
  align-items: center;
  flex: 1;

  .brand-avatar {
    width: 28px;
    height: 28px;
    background: var(--primary);
    border-radius: 6px;
    margin-right: 12px;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4375;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--white);
  }

  .text-truncate {
    flex: 1;
  }

  .brand-status {
    width: 20px;
    height: 20px;
    position: relative;
    margin-left: 4px;
    flex-shrink: 0;

    &::before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: #757575;
      position: absolute;
      left: 6px;
      top: 6px;
    }

    &.status-active::before {
      background: #43ca21;
    }

    &.status-expired::before {
      background: #d90125;
    }

    &.status-pending::before {
      background: #ffb257;
    }
  }
}

.brand-select .ng-dropdown-panel-items {
  min-height: 2.875rem;
  background-color: white;
}

.brand-select .ng-value-label {
  height: 1.375rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  color: #111111;
}

.brand-select.ng-select:not(.with-spinner) .ng-spinner-loader {
  display: none;
}

// Brand Select END

/**
  Time Select START
*/

.time-select.ng-select.ng-select-opened {
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--primary);

  .ng-arrow-wrapper .ng-arrow {
    opacity: 1;
    rotate: 180deg;
  }
}

.time-select.ng-select.ng-select-filtered {
  .ng-value-label {
    visibility: hidden;
  }
}

.time-select.ng-select {
  height: 44px;
  border-radius: 4px;
  padding: 0;
  border: 1px solid #e2e3e4;
  background-color: transparent;

  .ng-arrow-wrapper .ng-arrow {
    opacity: 0.3;
    transition: rotate 0.1s;
    width: 0.6669rem;
    height: 0.375rem;
    margin-right: 0.5rem;
    background-image: url('/assets/images/chevron-down-dark.svg');
    background-repeat: no-repeat;
    background-color: transparent;
  }
}

.time-select.ng-select:not(.ng-select-opened):hover {
  border-color: #e2e3e4;

  .ng-arrow-wrapper .ng-arrow {
    opacity: 1;
  }
}

.time-select.ng-select-disabled .ng-value-label {
  color: rgba(#bdbdbd, 0.8);
}

.time-select.ng-dropdown-panel {
  border: 1px solid #e2e3e4;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0;
  width: 80px !important;
  margin-top: 4px;
  margin-bottom: 4px;
  overflow: hidden;
}

.time-select.ng-select .ng-select-container {
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.time-select .ng-select-container .ng-value-container {
  padding-left: 8px;
}

.time-select.ng-select .ng-select-container .ng-value-container .ng-input {
  padding-right: 28px;
  padding-left: 8px;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
}

.time-select.ng-select .ng-arrow-wrapper {
  display: flex;
}

.time-select.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 344px;
}

.time-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a4e52;
  font-size: 1rem;
  line-height: 1.375;
  position: relative;
}

.time-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(.ng-option-disabled):hover {
  background-color: #f4f5f8;

  .ng-option-label {
    color: #111111;
  }
}

.time-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.time-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:hover {
  background: #ffffff;

  .ng-option-label {
    font-weight: 700;
    color: var(--primary);
  }
}

.time-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  cursor: default;
}

.time-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  cursor: default;
}

.time-select .ng-option-label {
  font-weight: 400;
  line-height: 1.375;
  color: #111111;
  text-align: center;
  flex: 1;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.time-select .ng-dropdown-panel-items {
  min-height: 2.875rem;
  background-color: white;
}

.time-select.not-found.ng-dropdown-panel {
  display: none;
}

.time-select .ng-value {
  text-align: center;
  flex: 1;
}

.time-select .ng-value-label {
  font-size: 15px;
  line-height: 1.364;
  font-weight: 400;
  color: #111111;
  width: 44px;
  text-align: center;
}

.time-select.ng-select:not(.with-spinner) .ng-spinner-loader {
  display: none;
}

.time-select.ng-select .ng-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.364;
  text-align: center;
  color: #bababa;
  flex: 1;
}

.time-select.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.time-select.ng-select .ng-select-container .ng-value-container .ng-input > input {
  text-align: center;
  font-size: 15px;
  line-height: 1.364;
  font-weight: 400;
  color: #111111;
  margin-left: 5px;
  cursor: pointer;
}

/**
  Time Select END
*/

.period-selection-form .rkt-form-select.ng-select {
  border: 1px solid #e2e3e4;
  background-color: #ffffff;
  min-width: 150px;
}
