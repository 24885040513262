$font-size-root: null !default;
$font-size-base: 1rem !default;
$enable-smooth-scroll: true !default;

:root {
  --btn-primary-bg: var(--primary);
  --btn-primary-hover-bg: #1f7984;
  --btn-primary-color: #ffffff;
  --btn-secondary-bg: var(--secondary);
  --btn-secondary-hover-bg: #c1e0e2;
  --btn-secondary-color: #111111;
  --btn-tertiary-bg: transparent;
  --btn-tertiary-hover-bg: #f0f6f6;
  --btn-tertiary-color: #4a4a4a;
  --btn-tertiary-hover-color: #111111;
  --btn-link-bg: transparent;
  --btn-link-hover-bg: transparent;
  --btn-link-color: #4a4a4a;
  --btn-link-hover-color: var(--primary);
  --btn-danger-bg: transparent;
  --btn-danger-hover-bg: rgb(var(--danger-rbg) 0.2);
  --btn-danger-color: var(--danger);
  --btn-danger-hover-color: #fa3d3d;
  --btn-font-size: 15px;
}

$body-font: 'Nunito Sans', sans-serif;
